








































































import Vue from 'vue'
import {TranslateResult} from 'vue-i18n'
import {Component, Prop} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {Fragment} from 'vue-fragment'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

type TrendingTokens = {
  title: TranslateResult
  timeTitle: TranslateResult
  seeAll: TranslateResult
  tokens: Tokens[]
}

type Tokens = {
  title: string | null | undefined
  imageUrl: string | null | undefined
  priceTitle: TranslateResult
  priceValue: string
  volumeTitle: TranslateResult
  volumeValue: number | null
}

@Component({
  components: {PercentageProgress, Fragment},
})
export default class TrendingTokensCard extends Vue {
  @Prop({type: Array, required: true}) tokens!: TokensTableItem[]

  items: TrendingTokens = {
    title: this.$t('components.cards.TrendingTokensCard.title'),
    timeTitle: this.$t('components.cards.TrendingTokensCard.timeTitle'),
    seeAll: this.$t('components.cards.TrendingTokensCard.seeAll'),
    tokens: [],
  }

  async mounted() {
    await this.getData()
  }

  private async getData() {
    for (const token of this.tokens) {
      this.items.tokens.push({
        title: token.symbol,
        imageUrl: token.imageUrl,
        priceTitle: this.$t('components.cards.TrendingTokensCard.priceTitle'),
        priceValue: token.marketInformation?.currentPrice?.toString() || '',
        volumeTitle: this.$t('components.cards.TrendingTokensCard.volumeTitle'),
        volumeValue:
          Number(token.marketInformation?.priceVariationLastDay) || 0,
      })
    }
  }
}
