









































































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import {TranslateResult} from 'vue-i18n'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import moment from 'moment'
import {BlockchainHourlyCache} from '@/model/resource/BlockchainHourlyCache'
import {Fragment} from 'vue-fragment'

type N3Statistics = {
  title: TranslateResult
  timeTitle: TranslateResult
  timeValue: TranslateResult | string
  statistics: Statistics[]
}

type Statistics = {
  title: TranslateResult
  value: string
  weekTitle: TranslateResult
  weekValue: number
}

@Component({
  components: {PercentageProgress, Fragment},
})
export default class N3StatisticsCard extends Vue {
  @Prop({type: Array, required: true})
  blockchainHourlyCacheCurrentDayItems!: BlockchainHourlyCache[]
  @Prop({type: Array, required: true})
  blockchainHourlyCacheLastWeekItems!: BlockchainHourlyCache[]

  items: N3Statistics = {
    title: this.$t('components.cards.N3StatisticsCard.title'),
    timeTitle: this.$t('components.cards.N3StatisticsCard.timeTitle'),
    timeValue: '',
    statistics: [],
  }

  private static getPercentage(total: number | null, value: number): number {
    if (!total || total === 0) return 0

    return +((value / total) * 100).toPrecision(1)
  }

  async mounted() {
    await this.getData()
  }

  getImageUrl(index: number) {
    switch (index) {
      case 0:
        return require('@/assets/img/networkContracts.svg')
      case 1:
        return require('@/assets/img/addresses.svg')
      case 2:
        return require('@/assets/img/allTimeTransactions.svg')
    }
  }

  private async getData() {
    if (
      this.blockchainHourlyCacheCurrentDayItems.length > 0 &&
      this.blockchainHourlyCacheLastWeekItems.length > 0
    ) {
      this.items.timeValue = this.$t(
        'components.cards.N3StatisticsCard.timeValue',
        {
          date: moment(
            this.blockchainHourlyCacheCurrentDayItems[0].date
          ).format('ddd, HH:mm'),
        }
      )
      const hourlyCacheToday = this.blockchainHourlyCacheCurrentDayItems[0]
      const hourlyCache7DaysAgo = this.blockchainHourlyCacheLastWeekItems[0]

      const transactionCountToday = hourlyCacheToday.transactionCount ?? 0
      const transactionCount7DaysAgo = hourlyCache7DaysAgo.transactionCount ?? 0
      const tcDiffTodayWith7d = transactionCountToday - transactionCount7DaysAgo

      this.items.statistics.push({
        title: this.$t(
          'components.cards.N3StatisticsCard.networkContracts.title'
        ),
        value: hourlyCacheToday.contractCount?.toLocaleString('eng-us') ?? '',
        weekTitle: this.$t(
          'components.cards.N3StatisticsCard.networkContracts.weekTitle'
        ),
        weekValue:
          (hourlyCacheToday.contractCount || 0) -
          (hourlyCache7DaysAgo?.contractCount ?? 0),
      })

      this.items.statistics.push({
        title: this.$t('components.cards.N3StatisticsCard.addresses.title'),
        value: hourlyCacheToday.addressCount?.toLocaleString('eng-us') ?? '',
        weekTitle: this.$t(
          'components.cards.N3StatisticsCard.addresses.weekTitle'
        ),
        weekValue:
          (hourlyCacheToday.addressCount || 0) -
          (hourlyCache7DaysAgo?.addressCount ?? 0),
      })

      this.items.statistics.push({
        title: this.$t(
          'components.cards.N3StatisticsCard.allTimeTransactions.title'
        ),
        value: transactionCountToday.toLocaleString('eng-us') ?? '',
        weekTitle: this.$t(
          'components.cards.N3StatisticsCard.allTimeTransactions.weekTitle'
        ),
        weekValue: N3StatisticsCard.getPercentage(
          transactionCount7DaysAgo,
          tcDiffTodayWith7d
        ),
      })
    }
  }
}
