





















import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class GetGasOpenModalButton extends Mixins(MixinScreenSize) {
  async openGetGasModal() {
    await this.$walletAdapter.executeWithConnectedWallet(() => {
      this.$modal.open('getGasModal')
    })
  }
}
