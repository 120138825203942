



























































































































































































































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {ExpansibleCollection, IResource} from '@simpli/resource-collection'
import {DefaultSchema} from '@/schema/DefaultSchema'
import NftToggleButton from '@/components/misc/GenericToggleButton.vue'
import NftCollectionToggleButton from '@/components/misc/GenericToggleButton.vue'
import GenericToggleButton from '@/components/misc/GenericToggleButton.vue'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

@Component({
  components: {
    GenericToggleButton,
    NftCollectionToggleButton,
    NftToggleButton,
    BounceLoader,
  },
})
export default class ScrollableTable extends MixinScreenSize {
  @Prop({type: Boolean, default: false}) showViewAll!: boolean
  @Prop({type: String, default: ''}) viewAllText!: string
  @Prop({type: String, default: ''}) viewAllUrl!: string
  @Prop({type: Object, default: false}) collection!: ExpansibleCollection<
    IResource
  >
  @Prop({type: Object, required: true}) schema!: DefaultSchema
  @Prop({type: Array, required: true}) orderByList!: string[]
  @Prop({type: Function, required: false}) handleOrderAscDesc!: (
    key: number
  ) => void
  @Prop({type: Array, default: () => []}) excludeFields!: string[]
  @Prop({type: Array, default: () => []}) excludeTitles!: string[]
  @Prop({type: Boolean, default: true}) startWithLoading!: boolean
  @Prop({type: Boolean, default: false}) isHomepage!: boolean
  @Prop({type: Boolean, default: false}) isDappDetailedPage!: boolean
  @Prop({type: Boolean, default: false}) isTokensPage!: boolean

  isLoading: boolean = this.startWithLoading

  get allFields() {
    return this.schema.allFields.filter(
      field => !this.excludeFields.includes(field)
    )
  }

  get allHeaders() {
    const indexes = this.excludeFields.map(field =>
      this.schema.allFields.indexOf(field)
    )
    return this.schema.allHeaders.filter(
      (_header, index) => !indexes.includes(index)
    )
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  getHeaderLabel(val: string) {
    if (this.excludeTitles.includes(val)) return ''
    return this.allHeaders[this.allFields.indexOf(val)]
  }

  showMarketInformationLabel(field: string, index: number) {
    return (
      field === 'priceVariationLastWeekGraphic' &&
      !(this.collection.items[index] as TokensTableItem).marketInformation
    )
  }
}
