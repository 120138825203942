





























































































import {Component, Prop} from 'vue-property-decorator'
import TokensPriceTable from './tokensprice/TokensPriceTable.vue'
import NftCollectionTable from './nfts/NftCollectionTable.vue'
import Swiper from 'swiper'
import {MixinScreenSize} from './mixins/MixinScreenSize'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import ShowAllCollectionsToggle from '@/components/ShowAllCollectionsToggle.vue'

@Component({
  components: {
    ShowAllCollectionsToggle,
    TokensPriceTable,
    NftCollectionTable,
    PoweredBy,
    LastPriceUpdated,
  },
})
export default class TokenNftTable extends MixinScreenSize {
  @Prop({type: String, default: 'tokens'}) selectedTable!: string

  lastTimeUpdated = ''

  poweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
  ]

  isBeginning = true
  isEnd = true

  swiper!: Swiper

  get swiperOption() {
    return {
      pagination: {
        el: '.token-nft-swiper-pagination',
        clickable: true,
      },
      spaceBetween: 30,
    }
  }

  async initSwiper(swiper: Swiper) {
    this.swiper = swiper
    this.updateEvent()
  }

  handleChangeLastTimeUpdated(lastTimeUpdated: string) {
    this.lastTimeUpdated = lastTimeUpdated
  }

  updateEvent() {
    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd
  }

  prevSlide() {
    if (!this.swiper.isBeginning) {
      this.swiper.slidePrev()
    }
    this.updateEvent()
  }

  nextSlide() {
    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
    }
    this.updateEvent()
  }
}
