























































import {Vue, Component, Prop} from 'vue-property-decorator'
import SwapPoweredBy from '@/components/swapToken/SwapPoweredBy.vue'
import SwapTokenDetails from '@/components/swapToken/SwapTokenDetails.vue'
import SwapTokenInfo from '@/components/swapToken/SwapTokenInfo.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

@Component({
  components: {
    SwapTokenDetails,
    SwapPoweredBy,
    SwapTokenInfo,
  },
})
export default class SwapReviewStep extends Vue {
  @Prop({type: Object, required: true}) tokenToUse!: TokensTableItem
  @Prop({type: String, required: true}) amountToUse!: string
  @Prop({type: String, required: true}) amountToUseInDollar!: string
  @Prop({type: Object, required: true}) tokenToReceive!: TokensTableItem
  @Prop({type: String, required: true}) amountToReceive!: string
  @Prop({type: String, required: true}) amountToReceiveInDollar!: string

  handleProceedClick(): void {
    this.$emit('proceedClick')
  }
}
