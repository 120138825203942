
































import {Component, Prop} from 'vue-property-decorator'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {SimpleListNftCollectionSchema} from '@/schema/resource/NftCollection/SimpleListNftCollectionSchema'
import ScrollableTable from '@/components/ScrollableTable.vue'
import GenericToggleButton from '@/components/misc/GenericToggleButton.vue'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import ShowAllCollectionsToggle from '@/components/ShowAllCollectionsToggle.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'

@Component({
  components: {
    ShowAllCollectionsToggle,
    GenericToggleButton,
    ScrollableTable,
    PoweredBy,
    LastPriceUpdated,
  },
})
export default class NftCollectionTable extends MixinScreenSize {
  @Prop({type: Number, default: null}) perPage!: number
  @Prop({type: Boolean, default: false}) showViewAll!: boolean
  @Prop({type: Boolean, default: false}) isHomepage!: boolean

  collection = new NftCollectionCollection()
  schema = new SimpleListNftCollectionSchema()

  poweredByList = [
    {
      url: 'https://ghostmarket.io/',
      alt: 'ghostmarket icon',
      filename: 'ghostmarket-icon.svg',
    } as PoweredByItem,
  ]

  showAllCollectionsToggleEvent(val: boolean) {
    this.collection.showBoughtInOneMonth = !val
    this.$await.run('loadCollection', () => this.collection.queryAsExpansible())
  }

  async created() {
    this.collection.showBoughtInOneMonth = true
    this.applyResources()
    await this.populateResources()
  }

  applyResources() {
    this.collection.orderBy = 'monthVolume'
    this.collection.asc = false
    this.collection.perPage = this.collection.total

    if (this.isHomepage) {
      this.collection.perPage = this.perPage
    }
  }

  async populateResources() {
    await this.$await.run('loadCollection', () => this.collection.expand())
  }
}
