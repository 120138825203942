






















import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import TokenNftTable from '@/components/TokenNftTable.vue'
import TokenNftHeaderMenu from '@/components/TokenNftHeaderMenu.vue'

@Component({
  components: {
    TokenNftTable,
    TokenNftHeaderMenu,
  },
})
export default class TokenNftView extends Mixins(MixinScreenSize) {
  @Prop({type: String, required: false, default: 'text-title'})
  titleClass!: string

  selectedTable = 'tokens'

  setSelectedTable(val: string) {
    this.selectedTable = val
  }
}
