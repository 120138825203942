




























































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import GenericToggleButton from '@/components/misc/GenericToggleButton.vue'

@Component({
  components: {
    GenericToggleButton,
  },
})
export default class ShowAllCollectionsToggle extends MixinScreenSize {
  @Prop({type: Boolean, default: false}) showAllCollectionStateDefault!: Boolean

  displayTooltip = false

  showAllCollectionsState = this.showAllCollectionStateDefault

  get showAllCollectionsToolTipStyle() {
    return this.displayTooltip
      ? 'show-all-collections-toggle__tooltip'
      : 'show-all-collections-toggle__tooltip--inactive'
  }

  showAllCollectionsToggleEvent(result: boolean) {
    this.showAllCollectionsState = result
    this.$emit('showAllCollections', result)
  }
}
