



































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

export type PoweredByItem = {
  url: string
  alt: string
  filename: string
}

@Component
export default class PoweredBy extends MixinScreenSize {
  @Prop({type: Array}) items!: PoweredByItem[]
  @Prop({type: Boolean, default: false}) displayInline!: boolean

  getImageUrl(filename: string) {
    return require(`@/assets/img/${filename}`)
  }
}
