












































































import {Component, Mixins} from 'vue-property-decorator'
import TopDappsCard from '@/components/cards/TopDappsCard.vue'
import N3StatisticsCard from '@/components/cards/N3StatisticsCard.vue'
import TrendingTokensCard from '@/components/cards/TrendingTokensCard.vue'
import RecentlyAddedCard from '@/components/cards/RecentlyAddedCard.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {DappCollection} from '@/model/collection/DappCollection'
import {BlockchainHourlyCacheCollection} from '@/model/collection/BlockchainHourlyCacheCollection'
import {DappDailyCacheCollection} from '@/model/collection/DappDailyCacheCollection'
import moment from 'moment'
import {BlockchainHourlyCache} from '@/model/resource/BlockchainHourlyCache'
import {DappDailyCache} from '@/model/resource/DappDailyCache'
import {Dapp} from '@/model/resource/Dapp'
import Swiper from 'swiper'
import {NftCollection} from '@/model/resource/NftCollection'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import TopNftsCard from '@/components/cards/TopNftsCard.vue'
import GetGasOpenModalButton from '@/components/getGas/GetGasOpenModalButton.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'

@Component({
  components: {
    TopNftsCard,
    N3StatisticsCard,
    TopDappsCard,
    RecentlyAddedCard,
    TrendingTokensCard,
    GetGasOpenModalButton,
  },
})
export default class NetworkStatsView extends Mixins(MixinScreenSize) {
  isBeginning = true
  isEnd = true

  swiper!: Swiper

  blockchainHourlyCacheCurrentDayItems: BlockchainHourlyCache[] = []
  blockchainHourlyCacheLastWeekItems: BlockchainHourlyCache[] = []
  topDappsItems: Dapp[] = []
  topDappsDailyCacheItems: DappDailyCache[] = []
  recentlyAddedDappsItems: Dapp[] = []
  recentlyAddedDappsDailyCacheItems: DappDailyCache[] = []
  trendingTokensItems: TokensTableItem[] = []
  topNftsItems: NftCollection[] = []

  blockchainVersionCollection = new BlockchainVersionCollection()
  blockchainHourlyCacheCollection = new BlockchainHourlyCacheCollection()
  dappCollection = new DappCollection()
  dappDailyCacheCollection = new DappDailyCacheCollection()
  tokensTableItemsCollection = new TokensTableItemsCollection()
  nftCollectionCollection = new NftCollectionCollection()

  get swiperOption() {
    return {
      pagination: {
        el: '.stats-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 24,
        },
        1280: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 24,
        },
      },
    }
  }

  async mounted() {
    await this.populateResources()
  }

  async getN3BlockchainVersionId() {
    this.blockchainVersionCollection.networkIdentifier = 'neo3/mainnet'

    const {
      data: {items},
    } = await this.blockchainVersionCollection.listBlockchainVersion()

    return items[0].idBlockchainVersionPk
  }

  async getDappsId(orderBy: string) {
    this.dappCollection.orderBy = orderBy
    this.dappCollection.asc = false
    this.dappCollection.perPage = 3
    this.dappCollection.private = false

    const {
      data: {items},
    } = await this.dappCollection.listDapp()

    return items.map(item => item.idDappPk)
  }

  async populateN3LastWeekItems(idBlockchainVersion: number) {
    this.blockchainHourlyCacheCollection.startDate = moment()
      .subtract(1, 'week')
      .startOf('hour')
      .format()
    this.blockchainHourlyCacheCollection.idBlockchainVersionFk = idBlockchainVersion

    const {
      data: {items: blockchainHourlyCacheLastWeekItems},
    } = await this.blockchainHourlyCacheCollection.listBlockchainHourlyCache()

    this.blockchainHourlyCacheLastWeekItems = blockchainHourlyCacheLastWeekItems
  }

  async populateN3CurrentDayItems(idBlockchainVersion: number) {
    this.blockchainHourlyCacheCollection.orderBy = 'date'
    this.blockchainHourlyCacheCollection.startDate = moment()
      .startOf('day')
      .format()
    this.blockchainHourlyCacheCollection.asc = false
    this.blockchainHourlyCacheCollection.idBlockchainVersionFk = idBlockchainVersion

    const {
      data: {items: blockchainHourlyCacheCurrentDayItems},
    } = await this.blockchainHourlyCacheCollection.listBlockchainHourlyCache()

    this.blockchainHourlyCacheCurrentDayItems = blockchainHourlyCacheCurrentDayItems
  }

  async populateTopDappsItems() {
    this.dappCollection.orderBy = 'transactionCountCurrentWeek'
    this.dappCollection.asc = false
    this.dappCollection.private = false

    const {
      data: {items},
    } = await this.dappCollection.listDapp()

    this.topDappsItems = items
  }

  async populateRecentlyAddedDappsItems() {
    this.dappCollection.orderBy = 'publishedDate'
    this.dappCollection.asc = false

    const {
      data: {items},
    } = await this.dappCollection.listDapp()

    this.recentlyAddedDappsItems = items

    for (const dapp of items) {
      await this.populateRecentlyAddedDappsDailyCacheItems(dapp.idDappPk)
    }
  }

  async populateRecentlyAddedDappsDailyCacheItems(idDapp: number) {
    this.dappDailyCacheCollection.orderBy = 'date'
    this.dappDailyCacheCollection.asc = false
    this.dappDailyCacheCollection.idDappFk = idDapp

    const {
      data: {items},
    } = await this.dappDailyCacheCollection.listDappDailyCache()

    this.recentlyAddedDappsDailyCacheItems.push(items[0])
  }

  async populateTopDappsDailyCacheItems(idDapp: number) {
    this.dappDailyCacheCollection.orderBy = 'date'
    this.dappDailyCacheCollection.asc = false
    this.dappDailyCacheCollection.idDappFk = idDapp

    const {
      data: {items},
    } = await this.dappDailyCacheCollection.listDappDailyCache()

    this.topDappsDailyCacheItems.push(items[0])
  }

  async populateTrendingTokensItems() {
    this.tokensTableItemsCollection.orderBy = 'priceVariationLastDay'
    this.tokensTableItemsCollection.withoutTotal = true
    this.tokensTableItemsCollection.asc = false
    this.tokensTableItemsCollection.perPage = 3

    const {
      data: {items},
    } = await this.tokensTableItemsCollection.listTokensTableItems()

    this.trendingTokensItems = items
  }

  async populateTopNftsItems() {
    this.nftCollectionCollection.orderBy = 'monthVolume'
    this.nftCollectionCollection.asc = false
    this.nftCollectionCollection.perPage = 3

    const {
      data: {items},
    } = await this.nftCollectionCollection.listNftCollection()

    this.topNftsItems = items
  }

  async setN3Data() {
    const n3VersionId = await this.getN3BlockchainVersionId()

    await this.populateN3LastWeekItems(n3VersionId)
    await this.populateN3CurrentDayItems(n3VersionId)
  }

  async setTopDappsData() {
    const dappsIds = await this.getDappsId('transactionCountCurrentWeek')

    await this.populateTopDappsItems()

    for (const dappId of dappsIds) {
      await this.populateTopDappsDailyCacheItems(dappId)
    }
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [
      this.setN3Data(),
      this.setTopDappsData(),
      this.populateRecentlyAddedDappsItems(),
      this.populateTrendingTokensItems(),
      this.populateTopNftsItems(),
    ]

    await this.$await.run('loadNetworkStats', () => Promise.all(promises))
  }

  async initSwiper(swiper: Swiper) {
    this.swiper = swiper
    this.updateEvent()
    this.updateSwiper()
  }

  updateEvent() {
    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd
  }

  updateSwiper() {
    if (this.isMobile) {
      this.swiper.autoplay.start()
    } else {
      this.swiper.autoplay.stop()
    }
  }

  prevSlide() {
    if (!this.swiper.isBeginning) {
      this.swiper.slidePrev()
    }
    this.updateEvent()
  }

  nextSlide() {
    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
    }
    this.updateEvent()
  }
}
