






























import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import GasbotImageHeader from '@/components/gasbot/GasbotImageHeader.vue'
@Component({
  components: {GasbotImageHeader},
})
export default class GasbotHeader extends Mixins(MixinScreenSize) {
  @Prop({type: Boolean, default: true}) showSubtitle!: boolean
  @Prop({type: Boolean, default: true}) showImageHeader!: boolean
  @Prop({type: Boolean, default: true}) showTitleBox!: boolean
  @Prop({type: Boolean, default: true}) showAddToDiscord!: boolean
  @Prop({type: String, default: 'text-4xl md:text-title mt-12'})
  titleClass!: string
  @Prop({type: String, default: 'text-2xl md:text-4xl mb-6'})
  subtitleClass!: string

  url = this.$env.GASBOT_DISCORD_URL
}
