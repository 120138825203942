
















































































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import Swiper from 'swiper'
import {DappCollection} from '@/model/collection/DappCollection'
import DappCard from '@/components/cards/DappCard.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import AdapTableWrapper from '@simpli/vue-adap-table'

@Component({
  components: {DappCard},
})
export default class DappExploreList extends Mixins(MixinScreenSize) {
  @Prop({type: String, required: false, default: 'text-title'})
  titleClass!: string

  collection = new DappCollection()

  isBeginning = true
  isEnd = false

  async mounted() {
    this.applyResources()
    await this.populateResources()
  }

  applyResources() {
    this.collection.trending = true
    if (this.isMobile) {
      this.collection.perPage = 5
    } else {
      this.collection.perPage = 10
    }
    this.collection.hasContract = true
    this.collection.orderBy = 'score'
    this.collection.asc = false
    this.collection.private = false
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [this.collection.queryAsPage()]
    await this.$await.run('listDapp', () => Promise.all(promises))
    await this.$nextTick()

    // TO-DO: Fix lib
    // block loading of more pages
    this.collection.setCurrentPage(this.collection.lastPage)

    //@ts-ignore
    const swiper = this.$refs.adapSwiper.$refs.swiperComponent.$swiper
    if (swiper) this.updateEvent(swiper)
  }

  updateEvent(swiper: Swiper) {
    this.isBeginning = swiper.isBeginning
    this.isEnd = swiper.isEnd
  }

  get swiperOption() {
    return {
      pagination: {
        el: '.dapp-explore-swiper-pagination',
        clickable: true,
      },
      breakpoints: AdapTableWrapper.options.swiperOptions?.breakpoints,
    }
  }
}
