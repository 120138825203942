var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swap-token-to-receive-input"},[_c('div',{staticClass:"verti bg-pearl-aqua bg-opacity-25 px-6 pt-7 pb-4 sm:pb-7 rounded-3xl"},[_c('p',{staticClass:"font-bold text-secondary text-2xl text-center sm:text-left sm:text-xl uppercase mb-6 sm:mb-9  px-8 sm:px-0"},[_vm._v(" "+_vm._s(_vm.$t('components.swapTokenToReceiveInput.chooseAmountToReceive'))+" ")]),_c('div',{staticClass:"horiz items-center border-solid border-2 bg-white rounded-full py-2 px-7 mb-5 justify-between cursor-text",class:{
        'border-light-cyan-blue': _vm.isValid === null,
        'border-coral-red': _vm.isValid === false,
        'border-primary': _vm.isValid === true,
      },on:{"click":_vm.focusOnInput}},[_c('swap-input',{staticClass:"swap-token-to-receive-input__input",attrs:{"token":_vm.token,"value":_vm.amount},on:{"input":_vm.handleInput}}),_c('div',{staticClass:"horiz items-center gap-x-2 flex-shrink-0",on:{"click":_vm.focusOnInput}},[_c('div',{staticClass:"mr-2 ml-4 h-6 sm:h-8 w-1 md:mx-3 rounded-full",class:{
            'bg-light-cyan-blue': _vm.isValid === null,
            'bg-coral-red': _vm.isValid === false,
            'bg-primary': _vm.isValid === true,
          }}),(_vm.token.imageUrl)?_c('img',{staticClass:"shadow-btn rounded-full w-10 h-10 sm:w-14 sm:h-14",attrs:{"src":_vm.token.imageUrl,"alt":"selected token icon"}}):_vm._e(),_c('span',{staticClass:"text-2xl sm:text-3xl font-medium text-slate-blue"},[_vm._v(" "+_vm._s(_vm.token.symbol)+" ")])])],1),_c('div',{staticClass:"horiz items-center justify-between relative"},[_c('span',{staticClass:"text-slate-blue font-medium text-xl mr-8"},[_vm._v(" "+_vm._s(_vm.$translate('components.swapTokenToReceiveInput.priceFormat', { price: _vm.amountInDollar, }))+" ")]),_c('tooltip',{attrs:{"label":_vm.$translate('components.swapTokenToReceiveInput.estimated'),"text":_vm.$translate('components.swapTokenToReceiveInput.estimatedTooltip')},on:{"onMobileOpen":_vm.setMobileTooltip}})],1)]),_c('tooltip-mobile-content',{attrs:{"show":_vm.mobileTooltipIsVisible,"text":_vm.$translate('components.swapTokenToReceiveInput.estimatedTooltip')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }