/**
 * TokensTableItemsCollection
 * @author Lopes
 */

import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseExpose,
} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import moment from 'moment/moment'
import {$} from '@/facade'

@HttpExclude()
export class TokensTableItemsCollection extends ExpansibleCollection<
  TokensTableItem
> {
  resource?: ITokensTableItemsCollectionResourceHolder

  @RequestExpose() minOrder: number | null = null
  @RequestExpose() withoutTotal: boolean | null = null
  @RequestExpose() slugDapp: string | null = null

  @ResponseExpose() lastTimeUpdated: string | null = null

  constructor() {
    super(TokensTableItem)
  }

  get lastTimeUpdatedFormatted() {
    const date = moment.utc(this.lastTimeUpdated)

    return date.format($.t('dateFormat.datetimeFull') as string)
  }

  async queryAsPage() {
    return this.listTokensTableItems()
  }

  async listTokensTableItems() {
    this.expandedItems = this.items

    return await Request.get(`/client/tokens-table-item`, {
      params: this.params,
    })
      .name('loadTokensTableItems')
      .as(this)
      .getResponse()
  }

  async listSwappableTokens() {
    this.expandedItems = this.items
    return await Request.get(`/client/tokens-table-item/swap-options`, {
      params: this.params,
    })
      .name('loadSwapOptions')
      .as(new Map<string, string[]>())
      .getData()
  }
}

export interface ITokensTableItemsCollectionResourceHolder {}
